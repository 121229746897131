import { NewStory } from "~/types";

export const STORY_PROMPTS = {
  story: {
    en: {
      prompt: ({ age, characters, subType, topic }: NewStory) => `
      You are a famous children's story writer. You have the following request from a children's book editor:
      Subject: ${topic}
      ${
        characters.length
          ? "Use characters: " +
            characters[0].name.toString() +
            ", " +
            characters[0].description.toString()
          : ""
      }
      Use words and expressions accessible to a ${age}-year-old child.
      Explore feelings and emotions.
      Create a positive and satisfying conclusion that resolves the intrigue and conveys the central message.
      The story must have a suitable title.
      Wrap the title in <h1> </h1>
      Wrap each paragraph in <p> </p>
      Step 1: Identify whether the subject is suitable for a child of ${age} years old. If not, stop here and display in the error key: """Not suitable""".
      Step 2: Create a captivating story based on the suggested subject. It should explore the topic from multiple angles.
      Step 3: Based on the story from step 2 and the following instructions, write a complete story of the ${subType} type that explores the provided topic.
      Write the story only.`,
    },
    ro: {
      prompt: ({ age, characters, subType, topic }: NewStory) => `
      "Ești un faimos scriitor de povești pentru copii. Ai primit următoarea cerere de la un editor de cărți pentru copii:
      Subiectul poveștii: """${topic}"""
      Tipul povestii: """${subType}"""
      ${
        characters.length
          ? "Foloseste personajele: " +
            characters[0].name.toString() +
            ", " +
            characters[0].description.toString()
          : ""
      }
      Utilizează cuvinte și expresii accesibile unui copil de ${age} ani.
      Explorează sentimente și emoții.
      Creează o concluzie pozitivă și satisfăcătoare care rezolvă intrigă și transmite mesajul central.
      Povestea trebuie să aibă un titlu potrivit.
      Încadrează titlul în <h1> </h1>
      Încadrează fiecare paragraf în <p> </p>
      Pasul 1: Identifică dacă subiectul este potrivit pentru un copil de ${age} ani. Dacă nu, oprește-te aici și răspunde cu: """Nu este potrivit""".
      Pasul 2: Creează o poveste captivantă bazată pe subiectul sugerat. Aceasta ar trebui să exploreze tema din mai multe unghiuri.
      Pasul 3: Pe baza poveștii din pasul 2 și a instrucțiunilor următoare, scrie o poveste completă care explorează subiectul oferit.
      Redă doar povestea"`,
    },
  },
  explanation: {
    en: {
      prompt: ({ age, topic }: any) =>
        `You are a teacher. You receive the following question from a ${age}-year-old child: "${topic}". Explain it to the child in a way he can understand. Do not use any form of greeting. Place the question between <h1> </h1> tags. Put the paragraphs of the answer in <p> </p> tags.`,
    },
    ro: {
      prompt: ({ age, topic }: any) =>
        `Esti un invatator. primesti urmatoarea intrebare de la un copil de ${age} ani: "${topic}". explica-i copilului pe intelesul lui. nu folosi nicio forma de salut. pune intrebarea intre <h1> </h1>. Paragrafele raspunsului pune-le in <p> </p>.`,
    },
  },
};
