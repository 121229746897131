<template>
  <div>
    <label
      v-if="label"
      :for="id"
      class="mt-4 block mb-2 text-sm font-medium text-slate-900 dark:text-slate-400"
      >{{ label }}</label
    >
    <input
      :type="type"
      :id="first_name"
      :value="modelValue"
      :autocomplete="autocomplete"
      @input="$emit('update:modelValue', $event.target.value)"
      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
      :placeholder="placeholder ? placeholder : label"
      required
    />
    <p v-if="help" class="text-sm text-slate-500">{{ help }}</p>
  </div>
</template>

<script setup>
const props = defineProps({
  label: {
    type: String,
  },
  type: {
    type: String,
    default: "text",
  },
  placeholder: {
    type: String,
    required: false,
  },
  autocomplete: {
    type: String,
    default: "on",
  },
  help: {
    type: String,
    required: "",
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
});

const id = computed(() => props.label.replace(" ", "_"));
</script>

<style lang="scss" scoped></style>
