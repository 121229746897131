<template>
  <div class="w-full">
    <div class="mx-auto w-full">
      <RadioGroup v-model="selected">
        <label
          class="block mb-2 text-sm font-medium text-slate-900 dark:text-slate-400"
          >{{ label }}</label
        >
        <!-- <RadioGroupLabel>Server size</RadioGroupLabel> -->
        <div class="space-y-2">
          <RadioGroupOption
            as="template"
            v-for="option in options"
            :key="option.key"
            :value="option"
            v-slot="{ active, checked }"
          >
            <div
              :class="[
                active
                  ? 'ring-1 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-500'
                  : '',
                checked
                  ? 'bg-blue-50 dark:bg-blue-700'
                  : 'bg-white dark:bg-slate-700',
              ]"
              class="relative flex cursor-pointer rounded-lg px-5 py-4 ring-1 ring-slate-200 dark:ring-slate-800 focus:outline-none hover:bg-slate-100 dark:hover:bg-slate-800"
            >
              <div class="flex w-full items-center justify-between">
                <div class="flex items-center">
                  <div class="text-sm">
                    <RadioGroupLabel
                      as="p"
                      :class="
                        checked
                          ? 'text-slate-900 dark:text-slate-50'
                          : 'text-slate-900 dark:text-slate-50'
                      "
                      class="font-medium"
                    >
                      {{ option.label }}
                    </RadioGroupLabel>
                    <RadioGroupDescription
                      as="span"
                      :class="
                        checked
                          ? 'text-slate-500 dark:text-slate-200'
                          : 'text-slate-500 dark:text-slate-200'
                      "
                      class="inline"
                    >
                      <span>{{ option.description }}</span>
                    </RadioGroupDescription>
                  </div>
                </div>
                <div v-show="checked" class="shrink-0">
                  <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
                    <circle
                      cx="12"
                      cy="12"
                      r="12"
                      fill="#2563eb"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M7 13l3 3 7-7"
                      stroke="#2563eb"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </RadioGroupOption>
        </div>
      </RadioGroup>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
} from "@headlessui/vue";

const props = defineProps({
  options: {
    type: Array,
  },
  label: {
    type: String,
  },
});

const emit = defineEmits(["update"]);

const selected = ref(props?.options[0] || null);

watch(
  selected,
  () => {
    emit("update", selected.value.key);
  },
  {
    immediate: true,
  }
);
</script>
