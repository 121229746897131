<template>
  <div>
    <DialogTitle
      as="h2"
      class="text-lg font-medium leading-6 text-slate-600 dark:text-slate-400 mb-5 flex flex-col justify-start items-start"
    >
      <button
        @click="emit('back')"
        class="mb-4 text-slate-400 hover:text-slate-600 transition duration-300"
      >
        <Icon
          name="ph:caret-left-bold"
          size="16"
          class="hover:cursor-pointer"
        />
        <span class="text-sm">Back</span>
      </button>
      <div class="flex flex-row">
        <span class="text-2xl">{{ $t("ui.questionForm.title") }}</span>
        <CustomChildInlineSelect />
      </div>
    </DialogTitle>

    <FormBaseInput
      :label="$t('storyType.explanation.inputLabel')"
      placeholder="placeholder"
      help="help"
      v-model="form.topic"
    />

    <div class="mt-4 text-slate-500">
      <span class="text-xs font-medium">{{ $t("ui.examples") }}: </span>

      <span
        class="text-underline text-xs"
        @click="(ev) => (form.topic = ex)"
        v-for="ex in $t('storyType.explanation.examples', {
          returnObjects: true,
        })"
        :key="ex"
        >{{ ex }},
      </span>
    </div>
  </div>
</template>

<script setup>
import { DialogTitle } from "@headlessui/vue";

const form = reactive({
  topic: "",
});

defineExpose({
  form,
});

const emit = defineEmits(["back"]);
</script>

<style lang="scss" scoped></style>
