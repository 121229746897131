import i18next from "i18next";
import I18NextVue from "i18next-vue";
import LanguageDetector from "i18next-browser-languagedetector";

//locales
// import en from "../locales/en";
// import ro from "../locales/ro";

i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          welcome: "Welcome",
          ui: {
            age: "Age",
            explanation: "Explanation",
            placeholder: "Vegetables are healthy and good.",
            random: "Random",
            examples: "Examples",
            createStory: "Create Story",
            back: "Back",
            save: "Save",
            generateStory: "Generate Story",
            noStoriesMessage: "Start by creating a story",
            questionForm: {
              title: "Question from",
            },
            onboarding: {
              language: "Choose your language",
              chooseLanguage: "Save language",
            },
            accountSettings: {
              children: {
                nav: "Children",
                title: "Add your little ones",
                action: "Add child",
                name: "Name",
                dateOfBirth: "Birthday",
              },
              settings: {
                nav: "Settings",
              },
              signOut: {
                nav: "Sign Out",
              },
              feedback: {
                nav: "Requests/Feedback",
                title: "Send request/feedback",
                action: "Send",
              },
            },
          },
          storyType: {
            story: {
              type: "story",
              typeLabel: "Pick a type of story",
              description:
                "A fairy tale or an adventure, a story to spark imagination.",
              inputLabel: "What's the story about?",
              inputPlaceholder: "Honesty is the best policy",
              characters: {
                sectionTitle: "Characters",
                character: "Character",
                name: "Character Name",
                namePlaceholder: "Arthur the Knight",
                description: "Description",
                descriptionPlaceholder:
                  "A brave and wise knight that talks in rhymes",
              },
              subTypes: [
                {
                  label: "Adventure",
                  key: "adventure",
                  description:
                    "An adventure story with hurdles and challenges.",
                },
                {
                  label: "Fairy Tale",
                  key: "fairyTale",
                  description: "A fairy tale with fairies and magic.",
                },
                {
                  label: "History fiction",
                  key: "historyFiction",
                  description: "A story with historical fiction.",
                },
              ],
              examples: [
                "Honesty is the best policy",
                "Beauty is only skin-deep",
                "The importance of hard work",
                "Greed leads to downfall",
                "The power of love",
                "The consequences of selfishness",
                "Courage is rewarded",
                "Good vs. evil",
              ],
            },
            explanation: {
              type: "explanation",
              typeLabel: "Explanation",
              description: "A story that teaches your your child something.",
              inputLabel: "What is the explanation of the story?",
              inputPlaceholder: "Why did the dinosaurs went extinct",
              examples: [
                "Why did the dinosaurs went extinct",
                "How do planes fly?",
                "How do we see?",
                "How do plants grow?",
              ],
            },
          },
        },
      },
      ro: {
        translation: {
          welcome: "Bine ați venit",
          ui: {
            age: "Vârstă",
            explanation: "Explicație",
            placeholder: "Legumele sunt sănătoase și bune.",
            random: "Aleator",
            examples: "Exemple",
            createStory: "Creați Povestea",
            back: "Inapoi",
            save: "Salvează",
            generateStory: "Creaza Povestea",
            noStoriesMessage: "Începeți prin a crea o poveste",
            questionForm: {
              title: "Intrebare de la ",
            },
            onboarding: {
              language: "Alege limba",
              chooseLanguage: "Salvează limba",
            },
            accountSettings: {
              children: {
                nav: "Copii",
                title: "Adaugă-i pe cei mici",
                action: "Adaugă copil",
                name: "Nume",
                dateOfBirth: "Data nașterii",
              },
              settings: {
                nav: "Setări",
              },
              signOut: {
                nav: "Deloghează-te",
              },
              feedback: {
                nav: "Cereri/Feedback",
                title: "Trimite cerere/feedback",
                action: "Trimite",
              },
            },
          },
          storyType: {
            story: {
              type: "poveste",
              typeLabel: "Alegeți un tip de poveste",
              description:
                "O poveste cu zâne sau o aventură, o poveste care stârnește imaginația.",
              inputLabel: "Despre ce este povestea?",
              inputPlaceholder: "Onestitatea este cea mai bună politică",
              characters: {
                sectionTitle: "Personaje",
                character: "Personajul ",
                name: "Numele personajului",
                namePlaceholder: "Praslea cel Voinic",
                description: "Descrierea personajului",
                descriptionPlaceholder:
                  "A brave and wise knight that talks in rhymes",
              },
              subTypes: [
                {
                  label: "Aventură",
                  key: "aventura",
                  description:
                    "O poveste de aventură cu obstacole și provocări.",
                },
                {
                  label: "Poveste cu Zâne",
                  key: "povesteZane",
                  description: "O poveste cu zâne și magie.",
                },
                {
                  label: "Ficțiune istorică",
                  key: "fictiuneIstorică",
                  description: "O poveste cu elemente de ficțiune istorică.",
                },
              ],
              examples: [
                "Onestitatea este cea mai bună politică",
                "Frumusețea este doar la suprafață",
                "Importanța muncii grele",
                "Lăcomia duce la căderea în jos",
                "Puterea iubirii",
                "Consecințele egoismului",
                "Curajul este răsplătit",
                "Binele vs. răul",
              ],
            },
            explanation: {
              type: "explicație",
              typeLabel: "Intrebare",
              description: "Un raspuns cat o poveste.",
              inputLabel: "Care este intrebarea?",
              inputPlaceholder: "De ce au dispărut dinozaurii",
              examples: [
                "De ce au dispărut dinozaurii",
                "Cum zboară avioanele?",
                "Cum vedem?",
                "Cum cresc plantele?",
              ],
            },
          },
        },
      },
    },
  });

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(I18NextVue, { i18next });
});
