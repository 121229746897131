import { watchEffect, watch, ref, reactive } from "vue";
import { UserProfile } from "@/types";
import { toValue } from "@vueuse/core";

export const useUserPreferencesStore = defineStore("userPreferences", () => {
  const preferences = reactive<UserProfile>({
    lang: null,
    isOnboarded: false,
  });

  async function fetchUserSettings() {
    const res: any = await $fetch("/api/userProfile", {
      method: "GET",
    });

    if (res.status !== 200) {
      return false;
    }

    if (res.status === 200 && res.data.length > 0) {
      const { data } = res;
      preferences.lang = data[0].lang;
      preferences.isOnboarded = data[0].isOnboarded;

      return data[0].isOnboarded;
    }

    return false;
  }

  function setUserSetting<K extends keyof UserProfile>(key: K, value: any) {
    if (key in preferences) {
      preferences[key] = toValue(value);
    }

    // Update cookie every time the language is changed
    if (key === "lang") {
      const cookieLanguage = useCookie("sgpt_lang");
      cookieLanguage.value = toValue(value);
    }
  }

  watch(
    preferences,
    async () => {
      await $fetch("/api/userProfile", {
        method: "POST",
        body: preferences,
      });
    },
    { deep: true, immediate: false }
  );

  return {
    preferences,
    setUserSetting,
    fetchUserSettings,
  };
});
