import { STORY_PROMPTS } from "~/prompts";

export default (type, config) => {
  const lang = useCookie("sgpt_lang");
  if (!config || !lang) throw new Error("Missing entry or lang");

  if (type === "story") {
    return STORY_PROMPTS["story"][lang.value].prompt(config);
  }

  if (type === "explanation") {
    return STORY_PROMPTS["explanation"][lang.value].prompt(config);
  }

  return "";
};
