<template>
  <div class="w-fit z-50 ml-2">
    <label
      v-if="label"
      class="mt-4 block mb-2 text-sm font-medium text-slate-900 dark:text-slate-400"
      >{{ label }}</label
    >
    <Listbox v-model="selectedChild">
      <div class="relative bg-blue-50 p-2 -mt-2 rounded-md">
        <ListboxButton
          class="relative w-fit cursor-default hover:cursor-pointer pr-8 align-middle"
        >
          <span class="ml-1 block truncate text-2xl">
            <h3 class="inline-block">{{ selectedChild.childName }}</h3>
            <!-- <span
              class="inline-block text-xs py-1 px-2 ml-2 bg-indigo-100 text-indigo-600 rounded-md"
              >{{ selectedChild.age }} years old
            </span> -->
          </span>
          <!-- <span class="block truncate"
            >{{ selectedChild.childName }}
             -->
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
          >
            <!-- CHEVRON UP DOWN ICON -->
            <Icon
              @click="step = 1"
              name="ph:caret-up-down-bold"
              size="16"
              class="hover:cursor-pointer"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute mt-1 max-h-60 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-[999]"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="child in children"
              :key="child.childName"
              :value="child"
              as="template"
            >
              <li
                :class="[
                  active ? 'bg-blue-50 text-blue-900' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-10 pr-4',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                  >{{ child.childName }}</span
                >
                <span
                  v-if="selected"
                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-700"
                >
                  <!-- CHECK ICON -->
                  <Icon
                    name="ph:check-bold"
                    size="16"
                    class="hover:cursor-pointer"
                  />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { useChildrenStore } from "@/stores/useChildrenStore";
const props = defineProps({
  label: {
    type: String,
    required: true,
    default: "",
  },
});
const store = useChildrenStore();

const children = await store.children;

const selectedChild = ref(store.selectedChild || children?.[0]);

watchEffect(() => {
  store.saveSelectedChild(selectedChild);
});
</script>
