import { Child } from "~/types";
import { unref } from "vue";

export const useChildrenStore = defineStore("children", () => {
  const childrenState = ref<Child[]>([]);
  const user = useSupabaseUser();
  const selectedChild = ref<Child | null>(null);

  const children = computed(async () => {
    if (childrenState.value.length === 0) {
      await fetchChildren();
    }
    return childrenState.value;
  });

  async function fetchChildren() {
    if (user.value) {
      const response = await fetch("/api/children");

      childrenState.value = await response.json();
    }
  }

  function saveSelectedChild(child: Child) {
    selectedChild.value = unref(child);
  }

  return {
    childrenState,
    children,
    selectedChild,
    fetchChildren,
    saveSelectedChild,
  };
});
