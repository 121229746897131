<template>
  <div class="dark:bg-slate-900 bg-indigo-50">
    <div
      class="w-screen m-0 p-0 min-h-screen flex justify-center max-w-6xl mx-auto"
    >
      <NuxtLayout class="w-full">
        <NuxtPage />
        <CreateNewStoryModal />
      </NuxtLayout>
    </div>
  </div>
</template>
<script lang="ts" setup>
const user = useSupabaseUser();
import { useTranslation } from "i18next-vue";
import { useUserPreferencesStore } from "~/stores/useUserPreferencesStore";
const { i18next } = useTranslation();
const router = useRouter();
const route = useRoute();

const userPreferences = useUserPreferencesStore();
await userPreferences.fetchUserSettings();

const browserLanguage = computed(() => i18next.language);
const cookieLanguage = useCookie("sgpt_lang");

watch(
  browserLanguage,
  () => {
    if (!cookieLanguage.value) {
      userPreferences.setUserSetting("lang", browserLanguage);
      // cookieLanguage.value = browserLanguage.value;
    }
    if (
      cookieLanguage.value &&
      browserLanguage.value !== cookieLanguage.value
    ) {
      userPreferences.setUserSetting("lang", browserLanguage);
    }
  },
  { immediate: true }
);

watchEffect(async () => {
  if (user.value && route.path === "/") {
    console.log("user logged in");
    router.push("/stories");
  }
});
</script>
