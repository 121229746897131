import { default as account_45createdpb6qg4iHUTMeta } from "/opt/buildhome/repo/pages/account-created.vue?macro=true";
import { default as childrenPUnHoC4ssTMeta } from "/opt/buildhome/repo/pages/account-settings/children.vue?macro=true";
import { default as indexLqKnlizdKBMeta } from "/opt/buildhome/repo/pages/account-settings/index.vue?macro=true";
import { default as create_45accountOVc1ya8VluMeta } from "/opt/buildhome/repo/pages/create-account.vue?macro=true";
import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexwoB2xtYuHYMeta } from "/opt/buildhome/repo/pages/onboarding/1/index.vue?macro=true";
import { default as indexnFD7ii33hAMeta } from "/opt/buildhome/repo/pages/onboarding/2/index.vue?macro=true";
import { default as indexCSx38ydJwoMeta } from "/opt/buildhome/repo/pages/onboarding/index.vue?macro=true";
import { default as pricesZgUgUL3BAzMeta } from "/opt/buildhome/repo/pages/prices.vue?macro=true";
import { default as sign_45incExpy2WlpQMeta } from "/opt/buildhome/repo/pages/sign-in.vue?macro=true";
import { default as _91id_935iwNh8tJ1oMeta } from "/opt/buildhome/repo/pages/stories/[id].vue?macro=true";
import { default as indexlo36Enp2j7Meta } from "/opt/buildhome/repo/pages/stories/index.vue?macro=true";
import { default as syncxbW1iqBoYVMeta } from "/opt/buildhome/repo/pages/sync.vue?macro=true";
import { default as update_45passwordQJd8Wg8IONMeta } from "/opt/buildhome/repo/pages/update-password.vue?macro=true";
export default [
  {
    name: account_45createdpb6qg4iHUTMeta?.name ?? "account-created",
    path: account_45createdpb6qg4iHUTMeta?.path ?? "/account-created",
    meta: account_45createdpb6qg4iHUTMeta || {},
    alias: account_45createdpb6qg4iHUTMeta?.alias || [],
    redirect: account_45createdpb6qg4iHUTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account-created.vue").then(m => m.default || m)
  },
  {
    name: childrenPUnHoC4ssTMeta?.name ?? "account-settings-children",
    path: childrenPUnHoC4ssTMeta?.path ?? "/account-settings/children",
    meta: childrenPUnHoC4ssTMeta || {},
    alias: childrenPUnHoC4ssTMeta?.alias || [],
    redirect: childrenPUnHoC4ssTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account-settings/children.vue").then(m => m.default || m)
  },
  {
    name: indexLqKnlizdKBMeta?.name ?? "account-settings",
    path: indexLqKnlizdKBMeta?.path ?? "/account-settings",
    meta: indexLqKnlizdKBMeta || {},
    alias: indexLqKnlizdKBMeta?.alias || [],
    redirect: indexLqKnlizdKBMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: create_45accountOVc1ya8VluMeta?.name ?? "create-account",
    path: create_45accountOVc1ya8VluMeta?.path ?? "/create-account",
    meta: create_45accountOVc1ya8VluMeta || {},
    alias: create_45accountOVc1ya8VluMeta?.alias || [],
    redirect: create_45accountOVc1ya8VluMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/create-account.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordcBWiw0xyOQMeta?.name ?? "forgot-password",
    path: forgot_45passwordcBWiw0xyOQMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordcBWiw0xyOQMeta || {},
    alias: forgot_45passwordcBWiw0xyOQMeta?.alias || [],
    redirect: forgot_45passwordcBWiw0xyOQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexwoB2xtYuHYMeta?.name ?? "onboarding-1",
    path: indexwoB2xtYuHYMeta?.path ?? "/onboarding/1",
    meta: indexwoB2xtYuHYMeta || {},
    alias: indexwoB2xtYuHYMeta?.alias || [],
    redirect: indexwoB2xtYuHYMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/onboarding/1/index.vue").then(m => m.default || m)
  },
  {
    name: indexnFD7ii33hAMeta?.name ?? "onboarding-2",
    path: indexnFD7ii33hAMeta?.path ?? "/onboarding/2",
    meta: indexnFD7ii33hAMeta || {},
    alias: indexnFD7ii33hAMeta?.alias || [],
    redirect: indexnFD7ii33hAMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/onboarding/2/index.vue").then(m => m.default || m)
  },
  {
    name: indexCSx38ydJwoMeta?.name ?? "onboarding",
    path: indexCSx38ydJwoMeta?.path ?? "/onboarding",
    meta: indexCSx38ydJwoMeta || {},
    alias: indexCSx38ydJwoMeta?.alias || [],
    redirect: indexCSx38ydJwoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: pricesZgUgUL3BAzMeta?.name ?? "prices",
    path: pricesZgUgUL3BAzMeta?.path ?? "/prices",
    meta: pricesZgUgUL3BAzMeta || {},
    alias: pricesZgUgUL3BAzMeta?.alias || [],
    redirect: pricesZgUgUL3BAzMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/prices.vue").then(m => m.default || m)
  },
  {
    name: sign_45incExpy2WlpQMeta?.name ?? "sign-in",
    path: sign_45incExpy2WlpQMeta?.path ?? "/sign-in",
    meta: sign_45incExpy2WlpQMeta || {},
    alias: sign_45incExpy2WlpQMeta?.alias || [],
    redirect: sign_45incExpy2WlpQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: _91id_935iwNh8tJ1oMeta?.name ?? "stories-id",
    path: _91id_935iwNh8tJ1oMeta?.path ?? "/stories/:id()",
    meta: _91id_935iwNh8tJ1oMeta || {},
    alias: _91id_935iwNh8tJ1oMeta?.alias || [],
    redirect: _91id_935iwNh8tJ1oMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stories/[id].vue").then(m => m.default || m)
  },
  {
    name: indexlo36Enp2j7Meta?.name ?? "stories",
    path: indexlo36Enp2j7Meta?.path ?? "/stories",
    meta: indexlo36Enp2j7Meta || {},
    alias: indexlo36Enp2j7Meta?.alias || [],
    redirect: indexlo36Enp2j7Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stories/index.vue").then(m => m.default || m)
  },
  {
    name: syncxbW1iqBoYVMeta?.name ?? "sync",
    path: syncxbW1iqBoYVMeta?.path ?? "/sync",
    meta: syncxbW1iqBoYVMeta || {},
    alias: syncxbW1iqBoYVMeta?.alias || [],
    redirect: syncxbW1iqBoYVMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/sync.vue").then(m => m.default || m)
  },
  {
    name: update_45passwordQJd8Wg8IONMeta?.name ?? "update-password",
    path: update_45passwordQJd8Wg8IONMeta?.path ?? "/update-password",
    meta: update_45passwordQJd8Wg8IONMeta || {},
    alias: update_45passwordQJd8Wg8IONMeta?.alias || [],
    redirect: update_45passwordQJd8Wg8IONMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/update-password.vue").then(m => m.default || m)
  }
]