<template>
  <div>
    <DialogTitle
      as="h2"
      class="text-lg font-medium leading-6 text-slate-600 dark:text-slate-400 mb-5 flex flex-col justify-start items-start"
    >
      <button
        @click="emit('back')"
        class="mb-4 text-slate-400 hover:text-slate-600 transition duration-300"
      >
        <Icon
          name="ph:caret-left-bold"
          size="16"
          class="hover:cursor-pointer"
        />
        <span class="text-sm">{{ $t("ui.back") }}</span>
      </button>
      <div class="flex flex-row">
        <span class="text-2xl">Story for</span>
        <CustomChildInlineSelect />
      </div>
    </DialogTitle>
    <FormBaseInput
      :label="$t('storyType.story.inputLabel')"
      v-model="form.topic"
      class="mb-8"
    />
    <pre>{{ radioOptions }}</pre>
    <FormRadioGroup
      :label="$t('storyType.story.typeLabel')"
      :options="$t('storyType.story.subTypes', { returnObjects: true })"
      @update="(ev) => (form.subType = ev)"
      class="mb-8"
    />
    <div v-for="(character, index) in form.characters">
      <h4 class="text-xl mb-4">Character {{ index + 1 }}</h4>
      <FormBaseInput
        :label="$t('storyType.story.characters.name')"
        :help="$t('storyType.story.characters.namePlaceholder')"
        v-model="character.name"
        class="mb-8"
      />
      <FormBaseInput
        type="textarea"
        :label="$t('storyType.story.characters.description')"
        :help="$t('storyType.story.characters.descriptionPlaceholder')"
        v-model="character.description"
        class="mb-8"
      />
    </div>
  </div>
</template>

<script setup>
import { DialogTitle } from "@headlessui/vue";

const form = reactive({
  topic: "",
  characters: [
    {
      name: "",
      description: "",
    },
  ],
  subType: null,
});

defineExpose({
  form,
});
const emit = defineEmits(["back"]);
</script>

<style lang="scss" scoped></style>
