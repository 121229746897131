<template>
  <button
    type="button"
    class="rounded-full transition duration-300 bg-pink-600 text-pink-100 hover:bg-pink-500 hover:text-pink-50 dark:bg-pink-700 dark:text-pink-100 dark:hover:bg-pink-600"
    :class="{ [mdClasses]: size === 'md', [xlClasses]: size === 'lg' }"
  >
    <slot></slot>
  </button>
</template>

<script setup>
defineProps({
  size: {
    type: String,
    default: "md",
    validator: (value) => ["sm", "md", "lg"].includes(value),
  },
});

const mdClasses = "px-4 py-2 text-sm font-bold";
const xlClasses = "px-8 py-4 text-xl font-bold";
</script>

<style lang="scss" scoped></style>
